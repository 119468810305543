import { createContext } from 'react';
import { AgendaBlockType } from '../types/agendaBlockType';
import { AttendeeRSVP } from '../types/attendeeRSVP';

export const EventInfoContext = createContext<{
    eventIds: {eventId: number, eventDayId: number} | null,
    startTime: {id: number,  name: string} | null,
    endTime: {id: number,  name: string} | null,
    eventName: string | null,
    attachedDocumentsCount: number
    eventDescription: string | null,
    eventDate: moment.Moment | null,
    timeZone: { id: number; name: string; timezone?: string } | null,
    location: {id: number, name: string, supportText?: string | undefined, lng?: string, lat?: string} | null,
    locationNotes?: string,
    agenda: {
      id: string, 
      icon: string, 
      name: string, 
      description: string, 
      duration: {
        id: number;
        name: string;
        value?: number | undefined;
      }, 
      startTime: string, 
      endTime: string, 
      type: AgendaBlockType,
      index: number,
      isEditable: boolean,
    }[],
    invitedAttendees?: {
      attendees: {
        id: number,
        networkMemberId: number,
        status: 'added',
        rsvp: AttendeeRSVP | null,
        networkMember: {
          id: number,
          fullName: string,
          email: string,
          phone: string,
          businessName: string,
          address: string,
          title: string,
          userId: null | number,
          accountId: number,
          createdAt: string,
          updatedAt: string,
          user: {
            accountId: number,
            createdAt: string,
            email: string,
            fullName: string,
            id: number,
            phone?: string | null,
            status: string,
            supabaseId: string,
            updatedAt: string,
          } | null,
        }
      }[],
      count: number,
    },
    currentLastStep: number,
    slug: string,
  }>({
    eventIds: null,
    agenda: [],
    endTime: null,
    eventDate: null,
    eventDescription: null,
    eventName: null,
    invitedAttendees: {
      attendees: [],
      count: 0
    },
    location: null,
    locationNotes: '',
    startTime: null,
    timeZone: null,
    attachedDocumentsCount: 0,
    currentLastStep: 1,
    slug: ''
  });