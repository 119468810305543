import React from "react";
import { MapboxMap } from "../../../components/MapBox/Map";
import LocationButton from "../../../components/LocationButton";

interface Props {
  location: string,
  lng: number,
  lat: number
}

export default function AttendeeActions({ location, lat, lng }: Props) {
  return (
    <div className="reviewEventDetailsPage-actions">
      <h3 className="text-lg font-semibold color-gray-900">Get directions</h3>
      <div className="reviewEventDetailsPage-actions-direction">
        <div className="reviewEventDetailsPage-actions-direction-directionMap">
          <MapboxMap
            agendaBlockId={0}
            location={location}
            lng={lng}
            lat={lat}
            zoom={11.3}
            withoutControls
            fullHeight
          />
        </div>
        <LocationButton
          text="sm"
          withoutArrow
          name="Get direction to the event"
          lat={`${lat}`}
          lng={`${lat}`}
        />
      </div>
    </div>
  );
}
