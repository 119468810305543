import React from "react";
import { AttendeeRSVP } from "../../../types/attendeeRSVP";
import { AgendaBlockType } from "../../../types/agendaBlockType";
import { logoColor } from "../../../types/logoColor";
import { logoImage } from "../../../types/logoImage";
import EventReviewBlockV2 from "../../../components/EventBlock/EventReviewBlockV2";
import moment from "moment";
import { getEventTime } from "../../../utilities/getEventStartTime";

interface Props {
  isOrganizer: boolean;
  event: {
    slug: string;
    id: number;
    startTime: string;
    endTime?: string;
    name: string;
    description: string;
    location: string;
    locationNote?: string;
    timezone?: string;
    timezoneName?: string;
    startDate: string;
    endDate: string;
    locationLongitude: string | null;
    locationLatitude: string | null;
    isOrganizer: boolean;
    color: logoColor | null;
    pattern: logoImage | null;
    agenda: [
      {
        id: number;
        index: number;
        name: string;
        description: string;
        duration: number;
        type: AgendaBlockType;
        sections: {
          id: number;
          name: string;
          duration: number;
          documents: string[];
          agendaItem: string;
          agendaItemId: number;
          createdAt: string;
          updatedAt: string;
          index: number;
        }[];
        dayId: number;
        createdAt: string;
        updatedAt: string;
        mealBlockId: number;
      }
    ];
    attendees: [
      {
        id: number;
        email: string;
        fullName: string;
        userId: null | number;
        businessName: string;
        eventId: number;
        status: string;
        rsvp: null | string;
        inviterId: number;
        title: string;
        address: string;
        createdAt: string;
        updatedAt: string;
        networkMemberId: number;
      }
    ];
    documents: [
      {
        id: number;
        isUploaded: boolean;
        key: string;
        originalFileName: string;
        url: string | null;
      }
    ];
    days: [
      {
        id: number;
        startTime: string;
        agenda: [];
      }
    ];
    isPublished: true;
    rsvp: AttendeeRSVP;
    totalDuration: number;
  };
  eventLogo: logoImage;
  eventLogoColor: logoColor;
  setIsEditLogoModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isFetching: boolean;
}

export default function MainHero({
  isOrganizer,
  event,
  eventLogo,
  eventLogoColor,
  setIsEditLogoModalOpen,
  isFetching,
}: Props) {
  return (
    <header className="reviewEventDetailsPage-header">
      {isFetching ? null : (
        <>
          <EventReviewBlockV2
            iconIsEditable={isOrganizer}
            withoutButton
            slug=""
            description={event?.description || ""}
            timeDifference={event.totalDuration}
            color={
              +new Date(event.startTime).valueOf() < +new Date().valueOf()
                ? "pastEvent"
                : eventLogoColor
            }
            pattern={eventLogo}
            key={event.slug}
            onClick={() => isOrganizer && setIsEditLogoModalOpen(true)}
            name={event.name}
            date={moment(event.startDate).format("ll")}
            location={event.location}
            startTime={
              getEventTime(event!.startTime, event.timezoneName)?.name || ""
            }
            endTime={
              event.endTime
                ? getEventTime(event!.endTime, event.timezoneName)?.name
                : undefined
            }
          />
        </>
      )}
    </header>
  );
}
