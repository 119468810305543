import React, { useContext, useState } from "react";
import Notification from "../../../components/Notifications/Notification";
import NotLogginedUser from "../../../components/Modal/NotLogginedUser";
import { createPortal } from "react-dom";
import AttachDocumentModal from "../../../components/Modal/AttachDocumentModal";
import AttachLinkModal from "../../../components/Modal/AttachLinkModal";
import ModalAddAttendees from "../../../components/Modal/ModalAddAttendees";
import ModalAddNewAttende from "../../../components/Modal/ModalAddNewAttende";
import ModalAgenda from "../../../components/Modal/ModalAgenda";
import ModalCreateEventLogo from "../../../components/Modal/ModalCreateEventLogo";
import ModalNotificationNew from "../../../components/Modal/ModalNotificationNew";
import ReviewDocumentsModal from "../../../components/Modal/ReviewDocumentsModal";
import PaymentCheckout from "../../../components/PaymentModal/PaymentCheckout";
import { editEventLogo, postMealsSelections } from "../../../httpQueries/http";
import { AttendeeRSVP } from "../../../types/attendeeRSVP";
import { logoColor } from "../../../types/logoColor";
import { logoImage } from "../../../types/logoImage";
import { getEventTime } from "../../../utilities/getEventStartTime";
import { markTodoAsCompleted } from "../../../utilities/markTodoAsCompleted";
import PreviewPreselectedMealsInviteesMeals from "../SelectCustomizationAttendeesView/PreviewPreselectedMealsForAttendees";
import { NotificationValue } from "../../../utilities/NotificationContext";

interface Props {
  notLogginedUserIsOpen: boolean;
  setNotLogginedUserIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  slug: string | undefined;
  reviewAttachmentsIsOpen: boolean;
  setReviewAttachmentsIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  attachments: JSX.Element[];
  isAttachDocumentModalOpen: boolean;
  selectedFileModal: {
    id: number;
    name: string;
    img?: string | undefined;
  } | null;
  setSelectedFileModal: React.Dispatch<
    React.SetStateAction<{
      id: number;
      name: string;
      img?: string | undefined;
    } | null>
  >;
  setIsAttachDocumentModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  eventId: number;
  setDocuments: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
        url: string;
        id?: number | undefined;
      }[]
    >
  >;
  sizeIsDesktop: boolean;
  uploadedDocuments: {
    document: {
      id: number;
      isUploaded: boolean;
      key: string;
      originalFileName: string;
      url: string | null;
    };
    url?: string | undefined;
  }[];
  setLinks: React.Dispatch<
    React.SetStateAction<
      {
        url: string;
        id: number;
      }[]
    >
  >;
  currentEditableLink:
    | {
        url: string;
        id: number;
      }
    | undefined;
  createNewUserIsOpen: boolean;
  setCreateNewUserIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  attendeesNetwork:
    | {
        accountId: number;
        createdAt: string;
        email: string;
        fullName: string;
        id: number;
        phone?: string | undefined;
        updatedAt: string;
        address: string | null;
        businessName: string | null;
        title: string | null;
      }[]
    | undefined;
  orderMealsModalIsOpen: boolean;
  setOrderMealsModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  cartId: string | null;
  mealBlockIdForOrder: number;
  notificationModalIsOpen: {
    name: string;
    description: string;
    firstButtonName: string;
    secondButtonName: string;
    onFirstButtonClick: () => void;
    onSecondButtonClick: () => void;
  } | null;
  setNotificationModalIsOpen: React.Dispatch<
    React.SetStateAction<{
      name: string;
      description: string;
      firstButtonName: string;
      secondButtonName: string;
      onFirstButtonClick: () => void;
      onSecondButtonClick: () => void;
    } | null>
  >;
  isEditLogoModalOpen: boolean;
  isOrganizer: boolean;
  setIsEditLogoModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEventLogo: React.Dispatch<React.SetStateAction<logoImage>>;
  setEventLogoColor: React.Dispatch<React.SetStateAction<logoColor>>;
  selectedAddAttendees: {
    id: number;
    name: string;
    supportText?: string | undefined;
    value?: number | undefined;
  } | null;
  attendees:
    | {
        attendees: {
          id: number;
          networkMemberId: number;
          status: "added";
          rsvp: AttendeeRSVP | null;
          networkMember: {
            id: number;
            fullName: string;
            email: string;
            phone: string;
            businessName: string;
            address: string;
            title: string;
            userId: null | number;
            accountId: number;
            createdAt: string;
            updatedAt: string;
            user: {
              accountId: number;
              createdAt: string;
              email: string;
              fullName: string;
              id: number;
              phone?: string | null;
              status: string;
              supabaseId: string;
              updatedAt: string;
            } | null;
          };
          mealSelections: any[];
        }[];
        count: number;
      }
    | "isFetching";
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  setIsUsersInvitedQueryWorks: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedAddAttendees: React.Dispatch<
    React.SetStateAction<{
      id: number;
      name: string;
      supportText?: string | undefined;
      value?: number | undefined;
    } | null>
  >;
}

export default function RenderModals({
  notLogginedUserIsOpen,
  setNotLogginedUserIsOpen,
  slug,
  reviewAttachmentsIsOpen,
  setReviewAttachmentsIsOpen,
  attachments,
  selectedFileModal,
  isAttachDocumentModalOpen,
  setSelectedFileModal,
  setIsAttachDocumentModalOpen,
  eventId,
  setDocuments,
  sizeIsDesktop,
  uploadedDocuments,
  setLinks,
  currentEditableLink,
  createNewUserIsOpen,
  setCreateNewUserIsOpen,
  attendeesNetwork,
  orderMealsModalIsOpen,
  setOrderMealsModalIsOpen,
  cartId,
  mealBlockIdForOrder,
  notificationModalIsOpen,
  setNotificationModalIsOpen,
  isEditLogoModalOpen,
  isOrganizer,
  setIsEditLogoModalOpen,
  setEventLogo,
  setEventLogoColor,
  selectedAddAttendees,
  attendees,
  searchValue,
  setSearchValue,
  setIsUsersInvitedQueryWorks,
  setSelectedAddAttendees,
}: Props) {
  const token = localStorage.getItem("token");
  const { notification, setNotification } = useContext(NotificationValue);
  const [newUserFullName, setNewUserFullName] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserPhoneNumber, setNewUserPhoneNumber] = useState("");
  const [newUserBusinessName, setNewUserBusinessName] = useState("");
  const [newUserTitle, setNewUserTitle] = useState("");
  const [userUpdated, setUserUpdated] = useState<number | null>(null);

  return (
    <>
      {notification ? (
        <Notification
          isError={!!notification.isError}
          setIsOpen={() => {
            setNotification(null);
          }}
          title={notification.title}
          description={notification.description}
        />
      ) : null}
      {notLogginedUserIsOpen ? (
        <NotLogginedUser
          setShow={() => setNotLogginedUserIsOpen((prevState) => !prevState)}
          slug={slug || ""}
        />
      ) : null}
      {reviewAttachmentsIsOpen
        ? createPortal(
            <ReviewDocumentsModal
              setShow={() =>
                setReviewAttachmentsIsOpen((prevState) => !prevState)
              }
            >
              {attachments.map((item) => item)}
            </ReviewDocumentsModal>,
            document.getElementById("modal") as HTMLElement
          )
        : null}
      {isAttachDocumentModalOpen && selectedFileModal
        ? selectedFileModal.name === "Upload files"
          ? createPortal(
              <AttachDocumentModal
                setDocuments={setDocuments}
                widthIsMobile={!sizeIsDesktop}
                setSelectedFileModal={setSelectedFileModal}
                attachedFilesCount={uploadedDocuments.length}
                setShow={setIsAttachDocumentModalOpen}
                eventId={eventId}
              />,
              document.getElementById("modal") as HTMLElement
            )
          : createPortal(
              <AttachLinkModal
                setSelectedLinks={setLinks}
                setSelectedFileModal={setSelectedFileModal}
                setShow={setIsAttachDocumentModalOpen}
                eventId={eventId}
                editItem={currentEditableLink}
              />,
              document.getElementById("modal") as HTMLElement
            )
        : null}
      {createNewUserIsOpen
        ? createPortal(
            <ModalAddNewAttende
              setShow={() => setCreateNewUserIsOpen(false)}
              alreadyAddedEmails={
                attendeesNetwork?.map((attendee) => attendee.email) || []
              }
              setUserFullName={setNewUserFullName}
              userFullName={newUserFullName}
              setUserEmail={setNewUserEmail}
              userEmail={newUserEmail}
              setUserPhoneNumber={setNewUserPhoneNumber}
              userPhoneNumber={newUserPhoneNumber}
              setUserBusinessName={setNewUserBusinessName}
              userBusinessName={newUserBusinessName}
              setUserTitle={setNewUserTitle}
              userTitle={newUserTitle}
              eventId={eventId}
              setNewUserAdded={() => {}}
              isEdit={userUpdated}
              setUserUpdated={setUserUpdated}
              isDesktopSize={sizeIsDesktop}
            />,
            document.getElementById("modal") as HTMLElement
          )
        : null}
      {orderMealsModalIsOpen ? (
        <PaymentCheckout
          mealBlockId={mealBlockIdForOrder}
          alreadySettedCartId={cartId}
          setIsOpen={setOrderMealsModalIsOpen}
        />
      ) : null}
      {notificationModalIsOpen
        ? createPortal(
            <ModalNotificationNew
              setIsOpen={setNotificationModalIsOpen}
              name={notificationModalIsOpen.name}
              description={notificationModalIsOpen.description}
              onFirstButton={notificationModalIsOpen.onFirstButtonClick}
              onSecondButton={notificationModalIsOpen.onSecondButtonClick}
              firstButtonName={notificationModalIsOpen.firstButtonName}
              secondButtonName={notificationModalIsOpen.secondButtonName}
            />,
            document.getElementById("modal") as HTMLElement
          )
        : null}
      {isEditLogoModalOpen && token
        ? createPortal(
            <ModalCreateEventLogo
              onSave={(color: logoColor, image: logoImage) => {
                setEventLogo(image);
                setEventLogoColor(color);
                editEventLogo({
                  color,
                  pattern: image,
                  token,
                  id: eventId,
                  isOrganizer: isOrganizer,
                });
              }}
              setIsOpen={setIsEditLogoModalOpen}
            />,
            document.getElementById("modal") as HTMLElement
          )
        : null}
      {selectedAddAttendees?.name === "Add existing attendees" &&
      attendees !== "isFetching"
        ? sizeIsDesktop
          ? createPortal(
              <ModalAddAttendees
                attendees={
                  attendees
                    ? attendeesNetwork!.filter((attendee) => {
                        const invitedAttendeesEmails = attendees.attendees.map(
                          (item) =>
                            item.networkMember.user
                              ? item.networkMember.user.email
                              : item.networkMember.email
                        );

                        return !invitedAttendeesEmails.includes(attendee.email);
                      })
                    : attendeesNetwork!
                }
                setShow={setSelectedAddAttendees}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                eventId={eventId}
                setIsUsersInvitedQueryWorks={setIsUsersInvitedQueryWorks}
              />,
              document.getElementById("modal") as HTMLElement
            )
          : null
        : selectedAddAttendees?.name === "Add new"
        ? createPortal(
            <ModalAddNewAttende
              setShow={() => setSelectedAddAttendees(null)}
              alreadyAddedEmails={
                attendeesNetwork?.map((attendee) => attendee.email) || []
              }
              setUserFullName={setNewUserFullName}
              userFullName={newUserFullName}
              setUserEmail={setNewUserEmail}
              userEmail={newUserEmail}
              setUserPhoneNumber={setNewUserPhoneNumber}
              userPhoneNumber={newUserPhoneNumber}
              setUserBusinessName={setNewUserBusinessName}
              userBusinessName={newUserBusinessName}
              setUserTitle={setNewUserTitle}
              userTitle={newUserTitle}
              eventId={eventId}
              setNewUserAdded={() => {}}
              isEdit={userUpdated}
              setUserUpdated={setUserUpdated}
              isDesktopSize={sizeIsDesktop}
            />,
            document.getElementById("modal") as HTMLElement
          )
        : null}
    </>
  );
}
