import { AgendaBlockType } from "./agendaBlockType"
import { logoColor } from "./logoColor"
import { logoImage } from "./logoImage"
import { MealBlockChoice, MealBlockState, MealBlockType } from "./meals"

export enum TodoItemType {
  MealSelection = 'Meal selection',
  RespondToInvite = 'Respond to invite',
  MealOrder = 'Meal order',
  SubmitEvent = 'Submit event',
  ReviewDocument = 'Review document',
}

export interface TodoItem {
  type: TodoItemType,
  dueDate: string,
  eventId: number,
  eventSlug: string,
  eventName: string,
  mealBlockId?: number,
  isSystemTracked: boolean,
  isCompleted: boolean,
  createdAt: string
  id: number,
  userId: number,
  totalAttendeesCount: number,
  orderedAttendeesCount: number,
  event: {
    id: number,
    name: string,
    slug: string,
    description: string,
    accountId: number,
    location: string,
    locationNote: null | string,
    locationLongitude: string,
    locationLatitude: string,
    timezone: string,
    startDate: string,
    endDate: string,
    createdAt: string,
    updatedAt: string,
    isPublished: boolean,
    color: logoColor | null,
    pattern: logoImage | null
  },
  mealBlock: null | {
    id: number,
    agendaItemId: number,
    type: MealBlockType,
    choice: MealBlockChoice,
    restaurantId: string | null,
    customRestaurantId: null | number,
    restaurantName: string,
    cartId: null | string,
    realOrderId: null,
    state: MealBlockState,
    preSelectedMealsIds: string[] | null,
    createdAt: string,
    agendaItem: {
        id: number,
        index: number,
        name: string,
        description: string,
        duration: number,
        type: AgendaBlockType,
        dayId: number,
        mealBlockId: number,
        createdAt: string,
        updatedAt: string
    }
}
}

