import React from "react";
import CheckBox from "../CheckBox/CheckBox";
import CustomBadge from "../Badge/CustomBadge";
import warningTriangle from "../../assets/images/alert-triangle.svg";

interface Props {
  label: string;
  options: {
    name: string;
    price: number;
    customizations: any[];
    min_qty: number;
    max_qty: number;
    conditional_price: {};
    formatted_price: string;
    default_qty: number;
    option_id: string;
    isSelected: boolean;
    onClick: (e?: React.MouseEvent<HTMLElement>) => void;
  }[];
  selectedAmount: number;
  isRequired?: boolean;
}

export default function SelectOptions({
  label,
  options,
  isRequired,
  selectedAmount,
}: Props) {
  return (
    <div className="radioButtons">
      <div className="flex flex-column gap-4">
        <h3 className="radioButtons-label">{label}</h3>
        {isRequired ? (
          <div className="flex items-center gap-8">
            <CustomBadge
              text={"Required"}
              status={"warning"}
              img={warningTriangle}
            />
            <p className="text-xs font-regular color-gray-700">
              Select at least 1
            </p>
          </div>
        ) : null}
      </div>
      <div>
        {options?.map((option) => {          
          const isDisabled = (option.max_qty === selectedAmount) && !option.isSelected;

          return (
            <div
              className="radioButtons-button"
              key={option.option_id}
              onClick={isDisabled ? undefined : option.onClick}
            >
              <CheckBox
                onClick={option.onClick}
                isSelected={option.isSelected}
                disabled={isDisabled}
              />
              <div className="radioButtons-button-content">
                <h3 className="radioButtons-button-content-name">
                  {option.name}
                </h3>
                {option.price ? (
                  <p className="radioButtons-button-content-price">
                    {option.formatted_price}
                  </p>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
