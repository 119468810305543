import React from "react";
import arrowLeft from "../../../assets/images/arrow-narrow-left.svg";
import closeIcon from "../../../assets/images/x-close-black.svg";
import { useNavigate } from "react-router-dom";

interface Props {
  pageView: "attendee" | "organizer";
  setPageView: React.Dispatch<React.SetStateAction<"attendee" | "organizer">>;
  isScrolled: boolean;
  isOrganizer: boolean;
  setNotLogginedUserIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ReviewEventDetailsHeader({
  pageView,
  setPageView,
  isOrganizer,
  isScrolled,
  setNotLogginedUserIsOpen
}: Props) {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  return (
    <div
      className={`reviewEventDetailsPage-fixedHeader ${
        pageView === "attendee"
          ? "reviewEventDetailsPage-fixedHeader-attendeeView"
          : ""
      } ${isScrolled ? "reviewEventDetailsPage-fixedHeader-fixed" : ""}`}
    >
      <div className="flex gap-12 items-center">
        <img
          className="selectRestaurant-main-info-backButton"
          src={arrowLeft}
          alt=""
          onClick={() => {
            if (token) {
              if (pageView === "attendee") {
                setPageView("organizer");
              } else {
                navigate(
                  `/events?filter=${isOrganizer ? "organizer" : "attendee"}`
                );
              }
            }

            if (!token) {
              setNotLogginedUserIsOpen(true);
            }
          }}
        />
        <h3
          className={`xs font-semibold ${
            pageView === "attendee" ? "color-gray-25" : "color-gray-900"
          }`}
        >
          {pageView === "attendee"
            ? "Back to organizer view"
            : "Back to events"}
        </h3>
      </div>
      {pageView === "organizer" ? (
        <img
          src={closeIcon}
          alt=""
          onClick={() => navigate("/events?filter=organizer")}
        />
      ) : null}
    </div>
  );
}
