import React, { useEffect } from "react";
import RestaurantMenu from "./RestaurantMenu";
import { fetchUserSelections } from "../../../httpQueries/http";

interface Props {
  selectedRestaurant: {
    id: string | number;
    name: string;
    logo: string;
    status?: "Open" | "Close";
    address: string;
    distance?: number;
    cuisines?: string[];
    description: string,
  } | null;
  showSelectMealModal: boolean;
  menu: {
    name: string;
    subcategory_id: string;
    menu_item_list: {
      count: number;
      name: string;
      price: number;
      qty_available: null;
      unit_size: null;
      unit_of_measurement: string;
      description: string;
      is_available: boolean;
      image: string;
      customizations: [
        {
          name: string;
          min_choice_options: number;
          max_choice_options: number;
          options: [
            {
              name: string;
              price: number;
              customizations: any[];
              min_qty: number;
              max_qty: number;
              conditional_price: {};
              formatted_price: string;
              default_qty: number;
              option_id: string;
            }
          ];
          customization_id: string;
        }
      ];
      min_price: number;
      original_price: number;
      formatted_price: string;
      attributes: [];
      product_id: string;
      thumbnail_image: string;
      should_fetch_customizations: boolean;
      supports_image_scaling: boolean;
    }[];
  }[];
  setPreOrder: React.Dispatch<
    React.SetStateAction<
      {
        customizations: {
          customizationId: string;
          optionId: string[];
          markedPrice: number;
        }[];
        productId: string;
        markedPrice: number;
        notes: string;
        count: number,
      }[]
    >
  >;
  preOrder: {
    customizations: {
      customizationId: string;
      optionId: string[];
      markedPrice: number;
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
    count: number,
  }[];
  mealBlockId: number | undefined;
  setSelectMealsModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  asyncOnAddMeal: () => Promise<void>;
  isDesktopSize: boolean;
  setOrders: React.Dispatch<
    React.SetStateAction<
      {
        agendaBlockId: number;
        customizations: {
          customizationId: string;
          optionId: string[];
          markedPrice: number;
        }[];
        productId: string;
        markedPrice: number;
        notes: string;
        count: number;
      }[]
    >
  >;
  orders: {
    agendaBlockId: number;
    customizations: {
      customizationId: string;
      optionId: string[];
      markedPrice: number;
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
    count: number;
  }[];
  agendaBlockId: number,
}

export default function PreviewPreselectedMealsInviteesMeals({
  showSelectMealModal,
  setPreOrder,
  setSelectMealsModalIsOpen,
  preOrder,
  menu,
  mealBlockId,
  asyncOnAddMeal,
  selectedRestaurant,
  isDesktopSize,
  setOrders,
  orders,
  agendaBlockId
}: Props) {
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (mealBlockId) {
      fetchUserSelections({ id: mealBlockId, token }).then(userSelections => {
        setOrders(prevState => {
          const currentOrder = prevState.find(order => order.agendaBlockId === agendaBlockId);
          const products: {
            price: number,
            id: string,
            qty: number,
            customizations: { customizationId: string; optionId: string[]; markedPrice: number; }[],
          }[] = [];

          userSelections?.mealSelectionsData.map(selection => menu.find(customization => {
            const product = customization.menu_item_list.find(item => item.name === selection.productName);

            if (product) {
              products.push({
                price: product.price,
                id: product.product_id,
                qty: selection.quantity,
                customizations: selection.customizations.map(custom => ({
                  customizationId: custom.customizationId,
                  optionId: custom.optionId, 
                  markedPrice: custom.markedPrice,
                }))
              })
            }
          }))

          if (currentOrder) {
            return [...prevState.filter(order => order.agendaBlockId === agendaBlockId), ...products.map(product => ({
              agendaBlockId: mealBlockId,
              customizations: product.customizations,
              productId: product.id,
              markedPrice: product.price,
              notes: '',
              count: product.qty,
            }))];
          } else {
            return [...prevState, ...products.map(product => ({
              agendaBlockId: mealBlockId,
              customizations: product.customizations,
              productId: product.id,
              markedPrice: product.price,
              notes: '',
              count: product.qty,
            }))];
          }
        });
        setPreOrder(() => {
          const products: {
            markedPrice: number,
            notes: string,
            productId: string,
            count: number,
            customizations: { customizationId: string; optionId: string[]; markedPrice: number; }[],
          }[] = [];

          userSelections?.mealSelectionsData.map(selection => menu.find(customization => {
            const product = customization.menu_item_list.find(item => item.name === selection.productName);

            if (product) {
              products.push({
                markedPrice: product.price,
                productId: product.product_id,
                notes: '',
                count: selection.quantity,
                customizations: selection.customizations.map(custom => ({
                  customizationId: custom.customizationId,
                  optionId: custom.optionId, 
                  markedPrice: custom.markedPrice,
                }))
              })
            }
          }));

          return products;
        })
      });
    }
  }, [mealBlockId, menu]);

  useEffect(() => {
    if (showSelectMealModal) {
      window.scrollTo(0, 0);

      document.getElementById("modal")?.classList.add("modal-main-container");

      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "auto";
      document
        .getElementById("modal")
        ?.classList.remove("modal-main-container");
    };
  }, []);

  return (
    <div
      className={`selectMeals-container ${
        showSelectMealModal
          ? "modal-main-container-withBackdrop selectMeals-container-isOpen"
          : ""
      }`}
    >
      <div
        className={`selectMeals ${
          showSelectMealModal ? "selectMeals-isOpen" : ""
        }`}
      >
        <RestaurantMenu
          menu={menu}
          orderedMeals={preOrder}
          setOrderedMeals={setPreOrder}
          agendaBlockId={mealBlockId || 0}
          asyncOnAddMeal={asyncOnAddMeal}
          id={""}
          name={""}
          setShowSelectRestaurantModal={setSelectMealsModalIsOpen}
          setOrders={setOrders}
          orders={orders}
          selectedRestaurant={selectedRestaurant}
          isDesktopSize={isDesktopSize}
          showSelectMealModal={showSelectMealModal}
        />
      </div>
    </div>
  );
}
