import { createSelector } from "@reduxjs/toolkit";
import { TRootState } from "../store";

const refetchEventsFunction = (state: TRootState) => (state.refetchEventsFunction);

const getRefetchEventsFunction = createSelector(
  [refetchEventsFunction],
  (refetchEventsFunction) => refetchEventsFunction
);

export { getRefetchEventsFunction }