import React, { useState } from "react";
import arrowLeft from "../../assets/images/arrow-narrow-left.svg";
import { Button } from "../Buttons/Button";
import AgendaSubBlock from "./AgendaSubBlock";
import AgendaItem from "../Agenda/AgendaItem";
import { AgendaBlockType } from "../../types/agendaBlockType";
import { getTimeInMinutes } from "../../utilities/getTimeInMinutes";

interface Props {
  showAddSubBlockModal: boolean;
  setShowAddSubBlockModal: React.Dispatch<React.SetStateAction<boolean>>;
  block: {
    id: string;
    icon: string;
    name: string;
    description: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    };
    startTime: string;
    endTime: string;
    type: AgendaBlockType;
    index: number;
    isEditable: boolean;
  } | null;
  onAddBlock: () => void;
  setSubBlocks: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        name: string;
        startTime: string;
        endTime: string;
        duration: {
          id: number;
          name: string;
          value?: number | undefined;
        } | null;
        index: number;
        docsId: number[];
        agendaItemId: string;
      }[]
    >
  >;
  subBlocks: {
    id: string;
    name: string;
    startTime: string;
    endTime: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    } | null;
    index: number;
    docsId: number[];
    agendaItemId: string;
  }[];
  agendaStartTime: string,
  agenda: {
    id: string;
    icon: string;
    name: string;
    description: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    };
    startTime: string;
    endTime: string;
    type: AgendaBlockType;
    index: number;
    isEditable: boolean;
  }[];
  setIsAgendaUpdated?: () => void,
  eventId: number,
}

export default function AddSubBlockModal({
  showAddSubBlockModal,
  setShowAddSubBlockModal,
  block,
  onAddBlock,
  setSubBlocks,
  subBlocks,
  agendaStartTime,
  agenda,
  setIsAgendaUpdated,
  eventId
}: Props) {
  const [editSubBlock, setEditSubBlock] = useState<{
    id: string;
    name: string;
    startTime: string;
    endTime: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    } | null;
    index: number;
    docsId: number[];
    agendaItemId: string;
  } | null>(null);
  const getCurrentSubBlocks = () => {
    if (subBlocks.length && block) {
      return subBlocks.filter(
        (subBlock) => subBlock.agendaItemId === block?.id
      );
    }

    return [];
  }

  const isDisabled = () => {
    if (subBlocks.length && block) {
      const subBlocksToDisplay = getCurrentSubBlocks();

      const disabled =
        !!subBlocksToDisplay.length &&
        getTimeInMinutes(
          subBlocksToDisplay[subBlocksToDisplay.length - 1]?.endTime
        ) >= getTimeInMinutes(block.endTime);

      return disabled
    }

    return false
  };

  if (!block) {
    return null;
  }

  return (
    <div
      className={`selectMeals ${
        showAddSubBlockModal ? "selectMeals-isOpen" : ""
      }`}
    >
      <div className="restaurantMenu">
        <div className="selectRestaurant-withoutPadding addAgendaBlock-main">
          <header className="selectRestaurant-main-header addAgendaBlock-header">
            <div
              className="flex flex-row gap-12 items-center border-gray-200 fullWidth"
              style={{
                position: "relative",
                zIndex: "30",
                padding: "0 0 24px 24px",
              }}
            >
              <img
                className="selectRestaurant-main-info-backButton"
                src={arrowLeft}
                alt=""
                onClick={() => setShowAddSubBlockModal(false)}
              />
              <p className="text-xl font-semibold color-gray-900">
                Back to agenda block
              </p>
            </div>
          </header>
          <main className="flex addAgendaBlock-main-container">
            <div className="addAgendaBlock-main-add overflowYScroll">
              <AgendaSubBlock
                setSubBlocks={setSubBlocks}
                startTime={
                  getCurrentSubBlocks().length
                    ? getCurrentSubBlocks()[getCurrentSubBlocks().length - 1].endTime
                    : block.startTime
                }
                disabled={isDisabled()}
                agendaItemId={block.id}
                editSubBlock={editSubBlock}
                setEditSubBlock={setEditSubBlock}
                subBlocks={subBlocks.filter(
                  (subBlock) => subBlock.agendaItemId === block?.id
                )}
                blockStartTime={block.startTime}
                blockEndTime={block.endTime}
                agenda={agenda}
                eventId={eventId}
              />
            </div>
            <div className="addAgendaBlock-main-preview overflowYScroll">
              <AgendaItem
                {...block}
                height={"full"}
                isSubBlockPreview
                subBlocks={subBlocks}
                setSubBlocks={setSubBlocks}
                setEditSubBlock={setEditSubBlock}
                subBlocksIsDraggable
                agendaStartTime={agendaStartTime}
                agenda={agenda}
              />
            </div>
          </main>
        </div>
        <footer className="selectRestaurant-footer restaurantMenu-footer px-24 py-24">
          <Button
            size="lg"
            hierarchy="newDesign-secondary"
            newDesignPaddingNone
            buttonType="regular"
            onClick={() => setShowAddSubBlockModal(false)}
          >
            Cancel
          </Button>
          <Button
            size="lg"
            hierarchy={"newDesign-primary"}
            newDesignPaddingNone
            buttonType="regular"
            onClick={() => {
              onAddBlock();
              setIsAgendaUpdated && setIsAgendaUpdated();
              setShowAddSubBlockModal(false);
            }}
          >
            Add to block
          </Button>
        </footer>
      </div>
    </div>
  );
}
