import React, { useEffect, useState } from "react";
import closeIcon from "../../assets/images/x-close-gray-lg.svg";
import Upload from "../UploadFile/Upload";
import axios from "axios";
import FileUploadBase from "../FileAttach/FileUploadBase";
import {
  deleteDocument,
  postDocument,
  updateDocument,
} from "../../httpQueries/http";
import { Button } from "../Buttons/Button";

interface Props {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  eventId: number;
  setIsFileAdded?: React.Dispatch<React.SetStateAction<boolean>>;
  attachedFilesCount?: number;
  setSelectedFileModal?: React.Dispatch<
    React.SetStateAction<{
      id: number;
      name: string;
      img?: string | undefined;
    } | null>
  >;
  widthIsMobile: boolean;
  setDocuments?: React.Dispatch<
    React.SetStateAction<{ name: string; url: string; id?: number }[]>
  >;
  addDocumentToSession?: (documentId: number) => void,
  removeDocumentFromSession?: (documentId: number) => void,
  // alreadyAttachedFiles: any[]
}

export default function AttachDocumentModal({
  setShow,
  eventId,
  setIsFileAdded,
  attachedFilesCount,
  setSelectedFileModal,
  widthIsMobile,
  setDocuments,
  addDocumentToSession,
  removeDocumentFromSession
}: Props) {
  const [selectedFile, setSelectedFile] = useState<any | null>(null);
  const [files, setFiles] = useState<any[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (selectedFile) {
      postDocument({
        id: eventId,
        token,
        body: {
          fileName: selectedFile.name,
          fileType: "application/pdf",
          fileSize: selectedFile.size,
        },
      }).then((response) => {
        try {
          {
            axios
              .put(response!.uploadUrl, selectedFile, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent) => {
                  const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total!
                  );

                  setUploadedFiles((prevState) => [
                    ...prevState.filter(
                      (item) => item.id !== response?.document.id
                    ),
                    { percentCompleted, id: response?.document.id },
                  ]);
                },
              })
              .then(() => {
                updateDocument({
                  token,
                  id: eventId,
                  documentId: response!.document.id,
                });
              });
          }
          setFiles((prevState) => [
            ...prevState,
            { id: response?.document.id, selectedFile },
          ]);
          response?.document.id && addDocumentToSession && addDocumentToSession(response?.document.id);
        } catch (e) {
          console.log(e);
        }
      });
    }
  }, [selectedFile]);

  return (
    <div className="modalNotification">
      <div className="modalNotification-container modalNotification-container-attachDocument modalNotification-container-inviteAttendees modalNotification-container-invite">
        <div className="modalNotification-header">
          <div className="">
            <img
              src={closeIcon}
              alt="close icon"
              className="modalNotification-header-actions-close modalInvite-closeIcon"
              onClick={() => {
                setShow(false);
                setIsFileAdded && setIsFileAdded((prevState) => !prevState);
                setSelectedFileModal && setSelectedFileModal(null);
              }}
            />
          </div>
          <div className="modalNotification-header-info">
            <h3>Attach documents</h3>
            <p>Upload and attach files to this project.</p>
          </div>
        </div>
        <div className="modalNotification-main">
          <div>
            <Upload
              widthIsMobile={widthIsMobile}
              isDisabled={(attachedFilesCount || 0) + files.length === 10}
              setSelectedFile={setSelectedFile}
              fileTypesInText="PDF, PNG, JPG, XLS or PPT (max. 10mb)"
              fileTypes=".pdf,.png,.jpg,.xls,.ppt"
            />
          </div>

          {files.length
            ? files.map((file) => (
                <FileUploadBase
                  onDelete={() => {
                    setFiles((prevState) =>
                      prevState.filter((item) => item.id !== file.id)
                    );
                    removeDocumentFromSession && removeDocumentFromSession(file.id);
                    deleteDocument({ token, id: eventId, documentId: file.id });
                  }}
                  progress={
                    uploadedFiles.find((item) => item.id === file.id)
                      ?.percentCompleted || 0
                  }
                  size={file.selectedFile.size}
                  name={file.selectedFile.name}
                  key={file.selectedFile.name}
                />
              ))
            : null}
        </div>
        <div className="modalNotification-actions modalNotification-actions-attachModal">
          <Button
            buttonType="regular"
            hierarchy="secondaryGray"
            size="lg"
            contentCenter
            fullWidth
            asyncOnClick={async () => {
              Promise.all(
                files.map((file) =>
                  deleteDocument({ token, id: eventId, documentId: file.id })
                )
              );
              setShow(false);
              setSelectedFileModal && setSelectedFileModal(null);
            }}
          >
            Cancel
          </Button>
          <Button
            buttonType="regular"
            hierarchy="primary"
            size="lg"
            contentCenter
            fullWidth
            // disabled={!!files.length}
            onClick={() => {
              setShow(false);
              setIsFileAdded && setIsFileAdded((prevState) => !prevState);
              setDocuments &&
                setDocuments(
                  files.map((file: any) => {
                    return {
                      id: file.id,
                      name: file.selectedFile.name,
                      url: file.selectedFile.url,
                    };
                  })
                );
              setSelectedFileModal && setSelectedFileModal(null);
            }}
          >
            Attach files
          </Button>
        </div>
      </div>
    </div>
  );
}
