import { AgendaBlockType } from "../types/agendaBlockType";

export function editSubBlockTime(
  subBlocks: {
    id: string;
    name: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    } | null;
    startTime: string;
    endTime: string;
    index: number;
    docsId: number[];
    agendaItemId: string;
  }[],
  timeOfStart: string | null,
  agenda: {
    id: string;
    startTime: string;
    type: AgendaBlockType;
  }[],
  setSubBlocks?: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        name: string;
        duration: {
          id: number;
          name: string;
          value?: number | undefined;
        } | null;
        startTime: string;
        endTime: string;
        index: number;
        docsId: number[];
        agendaItemId: string;
      }[]
    >
  >
) {
  //creating new subBlocks array
  const agendaBlockArray = [];

  for (let agendaBlock = 0; agendaBlock <= agenda.length - 1; agendaBlock++) {
    const startTime = agenda[agendaBlock].startTime;

    if (!startTime || agenda[agendaBlock].type !== AgendaBlockType.Session) {
      continue;
    }

    //going through all of previous subBlocks array items
    for (
      let i = 0;
      i <=
      subBlocks.filter((item) => item.agendaItemId === agenda[agendaBlock].id)
        .length -
        1;
      i++
    ) {
      let newItem = subBlocks.filter(
        (item) => item.agendaItemId === agenda[agendaBlock].id
      )[i];

      //changing subBlocks item start time if the item is not first in array we set to start time previous item end time
      newItem = {
        ...newItem,
        startTime:
          i === 0
            ? startTime
            : agendaBlockArray.filter(
                (item) => item.agendaItemId === agenda[agendaBlock].id
              )[i - 1].endTime,
      };

      let endHour;

      let endMinutes = 0;

      let partOfDay =
        i > 0 ? agendaBlockArray[i - 1].endTime?.slice(-2) : startTime?.slice(-2);

        
      if (newItem.duration) {
        if (newItem.duration.value! >= 60) {
          //set new subBlocks item end hour
          endHour =
            +newItem.startTime.split(":")[0] +
            Math.floor(newItem.duration.value! / 60);

          // set new subBlocks item end minutes
          endMinutes =
            newItem.duration.value! +
            +newItem.startTime.split(":")[1].slice(0, 2) -
            (endHour - +newItem.startTime.split(":")[0]) * 60;

          //if end time minutes bigger than 60 add 1 to end hour
          if (endMinutes >= 60) {
            endHour = endHour + Math.floor(endMinutes / 60);
            endMinutes = endMinutes - Math.floor(endMinutes / 60) * 60;
          }
        } else {
          endHour = +newItem.startTime?.split(":")[0];
          endMinutes =
            newItem.duration.value! +
            +newItem.startTime?.split(":")[1].slice(0, 2);

          if (endMinutes >= 60) {
            endHour = endHour + Math.floor(endMinutes / 60);
            endMinutes = endMinutes - Math.floor(endMinutes / 60) * 60;
          }
        }

        if (i > 0) {
          if (
            +agendaBlockArray[i - 1].endTime.split(":")[0] >= 8 &&
            +agendaBlockArray[i - 1].endTime.split(":")[0] <= 11 &&
            endHour >= 12
          ) {
            endHour -= endHour === 12 ? 0 : 12;
            partOfDay = partOfDay === "AM" ? "PM" : "AM";
          }
        } else {
          if (
            +startTime.split(":")[0] >= 8 &&
            +startTime.split(":")[0] <= 11 &&
            endHour >= 12
          ) {
            endHour -= endHour === 12 ? 0 : 12;
            partOfDay = partOfDay === "AM" ? "PM" : "AM";
          }
        }

        if (endHour >= 12) {
          endHour -= endHour === 12 ? 0 : 12;
        }
      }

      newItem = {
        ...newItem,
        endTime: `${endHour}:${
          endMinutes! > 0
            ? endMinutes < 10
              ? `0${endMinutes}`
              : endMinutes
            : "00"
        } ${partOfDay}`,
      };

      agendaBlockArray.push(newItem);
    }
  }

  if (setSubBlocks) {
    setSubBlocks(agendaBlockArray);
  }

  return agendaBlockArray;
}
