import React, { useEffect, useState } from "react";
import { Button } from "../../Buttons/Button";
import checkIcon from "../../../assets/images/check-white.svg";
import { createPortal } from "react-dom";
import SelectMealCustomizationModal from "../../Modal/SelectMealCustomizationModal";
import noMealIcon from "../../../assets/images/no-food-image.png";
import { fetchMenuItemCustomizations } from "../../../httpQueries/http";
import Loader from "../../Loader";

interface Props {
  name: string;
  description: string;
  price: string;
  image: string;
  count: number;
  setMenu: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
        subcategory_id: string;
        menu_item_list: {
          count: number;
          name: string;
          price: number;
          qty_available: null;
          unit_size: null;
          unit_of_measurement: string;
          description: string;
          is_available: boolean;
          image: string;
          customizations: [
            {
              name: string;
              min_choice_options: number;
              max_choice_options: number;
              options: [
                {
                  name: string;
                  price: number;
                  customizations: any[];
                  min_qty: number;
                  max_qty: number;
                  conditional_price: {};
                  formatted_price: string;
                  default_qty: number;
                  option_id: string;
                }
              ];
              customization_id: string;
            }
          ];
          min_price: number;
          original_price: number;
          formatted_price: string;
          attributes: [];
          product_id: string;
          thumbnail_image: string;
          should_fetch_customizations: boolean;
          supports_image_scaling: boolean;
        }[];
      }[]
    >
  >;
  categoryId: string;
  productId: string;
  setIsUpdatingTotalSum: React.Dispatch<React.SetStateAction<boolean>>;
  orderForAttendees: boolean;
  selectedAvailableMealsForAttendees: {
    id: number;
    products: { id: string; name: string }[];
  }[];
  setSelectedAvailableMealsForAttendees: React.Dispatch<
    React.SetStateAction<
      { id: number; products: { id: string; name: string }[] }[]
    >
  >;
  setTotalSum: React.Dispatch<React.SetStateAction<number>>;
  setAddAllMealsFromCategory: (
    value: React.SetStateAction<
      {
        categoryId: string;
        isToggled: boolean;
      }[]
    >
  ) => void;
  setOrderedMeals: React.Dispatch<
    React.SetStateAction<
      {
        customizations: {
          customizationId: string;
          optionId: string;
          markedPrice: number;
        }[];
        productId: string;
        markedPrice: number;
        notes: string;
      }[]
    >
  >;
  orderedMeals: {
    customizations: {
      customizationId: string;
      optionId: string;
      markedPrice: number;
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
  }[];
  markedPrice: number;
  setOrders: React.Dispatch<
    React.SetStateAction<
      {
        agendaBlockId: number;
        customizations: {
          customizationId: string;
          optionId: string[];
          markedPrice: number;
        }[];
        productId: string;
        markedPrice: number;
        notes: string;
        count: number;
      }[]
    >
  >;
  orders: {
    agendaBlockId: number;
    customizations: {
      customizationId: string;
      optionId: string[];
      markedPrice: number;
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
    count: number;
  }[];
  agendaBlockId: number;
  showSelectCustomizationModal: string;
  setShowSelectCustomizationModal: React.Dispatch<React.SetStateAction<string>>;
  checkIsAllMenuSelected: () => void;
}

export default function MenuItem({
  name,
  description,
  price,
  image,
  setMenu,
  categoryId,
  productId,
  selectedAvailableMealsForAttendees,
  setSelectedAvailableMealsForAttendees,
  orderForAttendees,
  setTotalSum,
  setAddAllMealsFromCategory,
  markedPrice,
  setOrders,
  showSelectCustomizationModal,
  setShowSelectCustomizationModal,
  agendaBlockId,
  orders,
  checkIsAllMenuSelected,
}: Props) {
  const token = localStorage.getItem("token");
  const [notes, setNotes] = useState("");
  const [preOrder, setPreOrder] = useState<
    | {
        agendaBlockId: number;
        customizations: {
          customizationId: string;
          optionId: string[];
          markedPrice: number;
        }[];
        productId: string;
        markedPrice: number;
        notes: string;
        count: number;
      }
    | undefined
  >();
  const [customizations, setCustomizations] = useState<
    | "loading"
    | {
        name: string;
        min_choice_options: number;
        max_choice_options: number;
        options: [
          {
            name: string;
            price: number;
            customizations: any[];
            min_qty: number;
            max_qty: number;
            conditional_price: {};
            formatted_price: string;
            default_qty: number;
            option_id: string;
          }
        ];
        customization_id: string;
      }[]
    | null
  >(null);

  return (
    <div
      className={`menuItem ${
        selectedAvailableMealsForAttendees
          .find((item) => item.id === agendaBlockId)
          ?.products.map((item) => item.id)
          .includes(productId)
          ? "menuItem-selected"
          : ""
      }`}
    >
      {showSelectCustomizationModal === productId &&
      typeof customizations !== "string"
        ? createPortal(
            <SelectMealCustomizationModal
              name={name}
              description={description}
              agendaBlockId={agendaBlockId}
              customizations={customizations || []}
              setShow={() =>
                setShowSelectCustomizationModal((prevState) =>
                  prevState.length ? "" : prevState
                )
              }
              productId={productId}
              markedPrice={markedPrice}
              onDescrease={() => {
                setPreOrder((prevState) => {
                  let newOrders: {
                    agendaBlockId: number;
                    customizations: {
                      customizationId: string;
                      optionId: string[];
                      markedPrice: number;
                    }[];
                    productId: string;
                    markedPrice: number;
                    notes: string;
                    count: number;
                  };

                  if (!prevState) {
                    newOrders = {
                      agendaBlockId,
                      customizations: [],
                      productId: productId,
                      markedPrice: markedPrice,
                      notes: notes,
                      count: 1,
                    };
                  } else {
                    newOrders = {
                      ...prevState,
                      count: prevState?.count - 1,
                    };
                  }

                  return newOrders;
                });
              }}
              onIncrease={() => {
                setPreOrder((prevState) => {
                  let newOrders: {
                    agendaBlockId: number;
                    customizations: {
                      customizationId: string;
                      optionId: string[];
                      markedPrice: number;
                    }[];
                    productId: string;
                    markedPrice: number;
                    notes: string;
                    count: number;
                  };

                  if (!prevState) {
                    newOrders = {
                      agendaBlockId,
                      customizations: [],
                      productId: productId,
                      markedPrice: markedPrice,
                      notes: notes,
                      count: 1,
                    };
                  } else {
                    newOrders = {
                      ...prevState,
                      count: prevState?.count + 1,
                    };
                  }

                  return newOrders;
                });
              }}
              setTotalSum={setTotalSum}
              setOrders={setPreOrder}
              count={preOrder?.count || 0}
              onAddMeal={() => {
                if (preOrder) {
                  preOrder.notes = notes;
                  setOrders((prevState) => {
                    if (
                      !!prevState.find(
                        (order) => order.productId === preOrder?.productId
                      ) ||
                      !!prevState.find((order) => order.productId === productId)
                    ) {
                      return [
                        ...prevState.filter(
                          (order) => order.productId !== productId
                        ),
                        preOrder,
                      ];
                    } else {
                      return [...prevState, preOrder];
                    }
                  });
                  setShowSelectCustomizationModal("");
                }
              }}
              preOrder={preOrder}
              onInputChange={(count) => {
                const newCount = +count;
                setPreOrder((prevState) => {
                  let newOrders: {
                    agendaBlockId: number;
                    customizations: {
                      customizationId: string;
                      optionId: string[];
                      markedPrice: number;
                    }[];
                    productId: string;
                    markedPrice: number;
                    notes: string;
                    count: number;
                  };

                  if (!prevState) {
                    newOrders = {
                      agendaBlockId,
                      customizations: [],
                      productId: productId,
                      markedPrice: markedPrice,
                      notes: notes,
                      count: newCount,
                    };
                  } else {
                    newOrders = {
                      ...prevState,
                      count: newCount,
                    };
                  }

                  return newOrders;
                });
              }}
              onTextFieldChange={(e) => {
                setNotes(e.target.value);
                setPreOrder((prevState) => {
                  if (preOrder) {
                    preOrder.notes = notes;

                    return preOrder;
                  }

                  return prevState;
                });
              }}
              textFieldValue={notes}
            />,
            document.getElementById("modal") as HTMLElement
          )
        : null}
      <img src={image ? image : noMealIcon} alt="" className="menuItem-image" />
      <div className="menuItem-content">
        <div className="flex flex-column gap-8">
          <h3 className="menuItem-content-name">{name}</h3>
          <p className="menuItem-content-description">{description}</p>
        </div>
        <div className="flex fullWidth spaceBetween">
          <p className="menuItem-content-price">{price}</p>
          {customizations === "loading" ? (
            <Loader size={"sm"} scale="3.3" />
          ) : (
            <Button
              buttonType="regular"
              size="lg"
              hierarchy="linkColor"
              paddingNone
              onClick={async () => {
                if (orderForAttendees) {
                  setSelectedAvailableMealsForAttendees((prevState) => {
                    if (
                      selectedAvailableMealsForAttendees
                        .find((item) => item.id === agendaBlockId)
                        ?.products.map((item) => item.id)
                        .includes(productId)
                    ) {
                      setAddAllMealsFromCategory((prevState) => [
                        ...prevState.filter(
                          (item) => item.categoryId !== categoryId
                        ),
                        { categoryId: categoryId, isToggled: false },
                      ]);
                      setMenu((prevState) =>
                        prevState.map((category) => {
                          if (category.subcategory_id === categoryId) {
                            return {
                              ...category,
                              menu_item_list: category.menu_item_list.map(
                                (menuItem) => {
                                  if (menuItem.product_id === productId) {
                                    setTotalSum(
                                      (prevState) =>
                                        prevState - menuItem.price / 100
                                    );

                                    return {
                                      ...menuItem,
                                      count: menuItem.count - 1,
                                    };
                                  }

                                  return menuItem;
                                }
                              ),
                            };
                          }

                          return category;
                        })
                      );

                      return [
                        ...prevState.filter(
                          (item) => item.id !== agendaBlockId
                        ),
                        {
                          id: agendaBlockId,
                          products:
                            selectedAvailableMealsForAttendees
                              .find((item) => item.id === agendaBlockId)
                              ?.products.filter(
                                (item) => item.id !== productId
                              ) || [],
                        },
                      ];
                    } else {
                      setMenu((prevState) =>
                        prevState.map((category) => {
                          if (category.subcategory_id === categoryId) {
                            return {
                              ...category,
                              menu_item_list: category.menu_item_list.map(
                                (menuItem) => {
                                  if (menuItem.product_id === productId) {
                                    setTotalSum((prevState) => {
                                      return prevState + menuItem.price / 100;
                                    });

                                    return {
                                      ...menuItem,
                                      count: menuItem.count + 1,
                                    };
                                  }

                                  return menuItem;
                                }
                              ),
                            };
                          }
                          //   setIsUpdatingTotalSum(true);

                          return category;
                        })
                      );

                      const currentOrder =
                        selectedAvailableMealsForAttendees.find(
                          (item) => item.id === agendaBlockId
                        );

                      const currentOrderProductsIds = currentOrder
                        ? [...currentOrder.products, { id: productId, name }]
                        : [{ id: productId, name }];

                      return [
                        ...prevState.filter(
                          (item) => item.id !== agendaBlockId
                        ),
                        {
                          id: agendaBlockId,
                          products: currentOrderProductsIds,
                        },
                      ];
                    }
                  });
                  checkIsAllMenuSelected();
                } else {
                  setCustomizations("loading");
                  const result = await fetchMenuItemCustomizations({
                    id: productId,
                    token,
                  });
                  setCustomizations(result || null);
                  setShowSelectCustomizationModal(productId);
                }
              }}
            >
              {orderForAttendees
                ? selectedAvailableMealsForAttendees
                    .find((item) => item.id === agendaBlockId)
                    ?.products.map((item) => item.id)
                    .includes(productId)
                  ? "Remove item"
                  : "Add item"
                : "Add meal"}
            </Button>
          )}
        </div>
      </div>
      {selectedAvailableMealsForAttendees
        .find((item) => item.id === agendaBlockId)
        ?.products.map((item) => item.id)
        .includes(productId) ? (
        <img
          src={checkIcon}
          alt=""
          className="fileUploadBase-downloaded restaurantItem-selected-icon"
        />
      ) : null}
    </div>
  );
}
