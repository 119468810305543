import TodoPreview from "../../../components/Todo/TodoPreview";
import {
  fetchMeals,
  fetchRestaurantMenu,
  fetchSpecificRestaurant,
} from "../../../httpQueries/http";
import { AttendeeRSVP } from "../../../types/attendeeRSVP";
import { MealBlockType } from "../../../types/meals";
import { TodoItem, TodoItemType } from "../../../types/todoItem";
import { markTodoAsCompleted } from "../../../utilities/markTodoAsCompleted";

interface Props {
  todoItems:
    | {
        todoItems: TodoItem[];
        total: number;
      }
    | undefined;
  onRSVPSelect(item: AttendeeRSVP): Promise<void>;
  setMealBlockId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setSelectMealsModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setNotLogginedUserIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEventAgendaMealBlock: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
        subcategory_id: string;
        menu_item_list: {
          count: number;
          name: string;
          price: number;
          qty_available: null;
          unit_size: null;
          unit_of_measurement: string;
          description: string;
          is_available: boolean;
          image: string;
          customizations: [
            {
              name: string;
              min_choice_options: number;
              max_choice_options: number;
              options: [
                {
                  name: string;
                  price: number;
                  customizations: any[];
                  min_qty: number;
                  max_qty: number;
                  conditional_price: {};
                  formatted_price: string;
                  default_qty: number;
                  option_id: string;
                }
              ];
              customization_id: string;
            }
          ];
          min_price: number;
          original_price: number;
          formatted_price: string;
          attributes: [];
          product_id: string;
          thumbnail_image: string;
          should_fetch_customizations: boolean;
          supports_image_scaling: boolean;
        }[];
      }[]
    >
  >;
  setSelectedRestaurant: React.Dispatch<
    React.SetStateAction<{
      id: string | number;
      name: string;
      logo: string;
      status?: "Open" | "Close" | undefined;
      address: string;
      distance?: number | undefined;
      cuisines?: string[] | undefined;
      description: string;
    } | null>
  >;
  setMealBlockIdForOrder: React.Dispatch<React.SetStateAction<number>>;
  setCartId: React.Dispatch<React.SetStateAction<string | null>>;
  setAgendaBlockId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setOrderMealsModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function GetTodoItems({
  todoItems,
  onRSVPSelect,
  setMealBlockId,
  setSelectMealsModalIsOpen,
  setEventAgendaMealBlock,
  setNotLogginedUserIsOpen,
  setSelectedRestaurant,
  setMealBlockIdForOrder,
  setCartId,
  setOrderMealsModalIsOpen,
  setAgendaBlockId
}: Props) {
  const token = localStorage.getItem("token");

  return todoItems?.todoItems
    .filter((todo) => !todo.isCompleted)
    .map((todo) => (
      <TodoPreview
        toDo={todo}
        onTodoClick={
          token
            ? todo.type === TodoItemType.MealSelection
              ? async () => {
                  const mealBlock = await fetchMeals({
                    id: todo.mealBlockId!,
                    token,
                  });

                  if (mealBlock?.mealBlock?.restaurantId) {
                    fetchRestaurantMenu({
                      id: mealBlock.mealBlock.restaurantId,
                      token,
                      isPickup:
                        mealBlock.mealBlock.type === MealBlockType.Pickup,
                    }).then((menu) => {
                      if (menu) {
                        const preSelectedMeals: {
                          name: string;
                          subcategory_id: string;
                          menu_item_list: {
                            count: number;
                            name: string;
                            price: number;
                            qty_available: null;
                            unit_size: null;
                            unit_of_measurement: string;
                            description: string;
                            is_available: boolean;
                            image: string;
                            customizations: [
                              {
                                name: string;
                                min_choice_options: number;
                                max_choice_options: number;
                                options: [
                                  {
                                    name: string;
                                    price: number;
                                    customizations: any[];
                                    min_qty: number;
                                    max_qty: number;
                                    conditional_price: {};
                                    formatted_price: string;
                                    default_qty: number;
                                    option_id: string;
                                  }
                                ];
                                customization_id: string;
                              }
                            ];
                            min_price: number;
                            original_price: number;
                            formatted_price: string;
                            attributes: [];
                            product_id: string;
                            thumbnail_image: string;
                            should_fetch_customizations: boolean;
                            supports_image_scaling: boolean;
                          }[];
                        }[] = [];

                        const mealsIds =
                          mealBlock.mealBlock.preSelectedMealsIds;

                        if (mealsIds?.length) {
                          menu.map((category) =>
                            category.menu_item_list.map((product) => {
                              if (mealsIds.includes(product.product_id)) {
                                const preSelectedCategories =
                                  preSelectedMeals.map(
                                    (item) => item.subcategory_id
                                  );
                                if (
                                  preSelectedCategories.includes(
                                    category.subcategory_id
                                  )
                                ) {
                                  const currentCategory = preSelectedMeals.find(
                                    (item) =>
                                      item.subcategory_id ===
                                      category.subcategory_id
                                  );

                                  if (currentCategory) {
                                    preSelectedMeals[
                                      preSelectedMeals.findIndex(
                                        (category) =>
                                          category.subcategory_id ===
                                          currentCategory.subcategory_id
                                      )
                                    ] = {
                                      ...currentCategory,
                                      menu_item_list: [
                                        ...currentCategory.menu_item_list,
                                        {
                                          count: 0,
                                          ...product,
                                        },
                                      ],
                                    };
                                  }
                                } else {
                                  preSelectedMeals.push({
                                    ...category,
                                    menu_item_list: [
                                      {
                                        count: 0,
                                        ...product,
                                      },
                                    ],
                                  });
                                }
                              }
                            })
                          );

                          setEventAgendaMealBlock(preSelectedMeals);
                        }
                      }
                    });

                    const restaurant = await fetchSpecificRestaurant({
                      id: mealBlock.mealBlock.restaurantId,
                      token,
                    });

                    setSelectedRestaurant(
                      restaurant
                        ? {
                            id: restaurant._id,
                            name: restaurant.name,
                            logo: restaurant.logo_photos[0],
                            status: restaurant.is_open ? "Open" : "Close",
                            address: restaurant.address.street_addr,
                            distance: restaurant.miles,
                            cuisines: restaurant.cuisines,
                            description: restaurant.description,
                          }
                        : null
                    );

                    setMealBlockId(todo.mealBlockId);
                    setAgendaBlockId(mealBlock.mealBlock.agendaItem.id);
                    setSelectMealsModalIsOpen(true);
                  }

                  await markTodoAsCompleted(todo, token);
                }
              : todo.type === TodoItemType.MealOrder
              ? async () => {
                  setMealBlockIdForOrder(todo.mealBlockId!);
                  setCartId(
                    todo.mealBlock?.cartId ? todo.mealBlock?.cartId : null
                  );
                  setOrderMealsModalIsOpen(true);
                  await markTodoAsCompleted(todo, token);
                }
              : todo.type === TodoItemType.RespondToInvite
              ? async (item: AttendeeRSVP | undefined) => {
                  if (item) {
                    await onRSVPSelect(item);
                    await markTodoAsCompleted(todo, token);
                  }
                }
              : async () => {}
            : async () => setNotLogginedUserIsOpen(true)
        }
        name={
          todo.type === TodoItemType.RespondToInvite
            ? "Event invite"
            : todo.type === TodoItemType.MealOrder
            ? "Meal order"
            : todo.type === TodoItemType.MealSelection
            ? "Meal selection"
            : todo.type === TodoItemType.SubmitEvent
            ? "Submit event"
            : todo.type === TodoItemType.ReviewDocument
            ? "Review document"
            : ""
        }
        description={
          todo.type === TodoItemType.RespondToInvite
            ? "Respond to the event invite"
            : todo.type === TodoItemType.MealOrder
            ? `Order meal for ${todo.event.name}`
            : todo.type === TodoItemType.MealSelection
            ? `Select meal for ${todo.event.name}`
            : todo.type === TodoItemType.SubmitEvent
            ? "Your event hasn’t been submitted."
            : todo.type === TodoItemType.ReviewDocument
            ? "Please, review the document"
            : ""
        }
        key={todo.id}
      />
    ));
}
