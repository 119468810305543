import React, { useEffect, useState } from "react";
import Upload from "../UploadFile/Upload";
import FileUploadBase from "../FileAttach/FileUploadBase";
import { deleteDocument, postDocument, updateDocument } from "../../httpQueries/http";
import axios from "axios";

export default function AttachDocumentsBlock(
  { disabled, addDocumentToSession, removeDocumentFromSession, eventId, clearFiles, setClearFiles }: {
    disabled?: boolean, 
    addDocumentToSession?: (documentId: number) => void,
    removeDocumentFromSession?: (documentId: number) => void, 
    eventId: number,
    clearFiles?: boolean,
    setClearFiles?: (value: React.SetStateAction<boolean>) => void
  }
) {
  const [selectedFile, setSelectedFile] = useState<any | null>(null);
  const [files, setFiles] = useState<any[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const token = localStorage.getItem("token");
  const attachedFilesCount = 0;

  useEffect(() => {
    if (selectedFile) {
      setUploadedFiles((prevState) => [
        ...prevState.filter(
          (item) => item.id !== selectedFile.name
        ),
        { percentCompleted: 100, id: selectedFile.name },
      ]);
      postDocument({
        id: eventId,
        token,
        body: {
          fileName: selectedFile.name,
          fileType: "application/pdf",
          fileSize: selectedFile.size,
        },
      }).then((response) => {
        try {
          {
            axios
              .put(response!.uploadUrl, selectedFile, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent) => {
                  const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total!
                  );

                  setUploadedFiles((prevState) => [
                    ...prevState.filter(
                      (item) => item.id !== response?.document.id
                    ),
                    { percentCompleted, id: response?.document.id },
                  ]);
                },
              })
              .then(() => {
                updateDocument({
                  token,
                  id: eventId,
                  documentId: response!.document.id,
                });
              });
          }
          setFiles((prevState) => [
            ...prevState,
            { id: response?.document.id, selectedFile },
          ]);
          response?.document.id && addDocumentToSession && addDocumentToSession(response?.document.id);
        } catch (e) {
          console.log(e);
        }
      });
    }
  }, [selectedFile]);

  useEffect(() => {
    if (clearFiles) {
      setFiles([]);
      setUploadedFiles([]);
      setClearFiles && setClearFiles(false);
    }
  }, [clearFiles])

  return (
    <div className="modalNotification-main">
      <div>
        <Upload
          widthIsMobile={false}
          isDisabled={disabled || ((attachedFilesCount || 0) + files.length === 10)}
          setSelectedFile={setSelectedFile}
          fileTypesInText="PDF, PNG, JPG, XLS or PPT (max. 10mb)"
          fileTypes=".pdf,.png,.jpg,.xls,.ppt"
        />
      </div>

      {files.length
        ? files.map((file) => (
            <FileUploadBase
              onDelete={() => {
                setFiles((prevState) =>
                  prevState.filter((item) => item.id !== file.id)
                );
                removeDocumentFromSession && removeDocumentFromSession(file.id);
                deleteDocument({ token, id: eventId, documentId: file.id });
              }}
              progress={
                uploadedFiles.find((item) => item.id === file.id)
                  ?.percentCompleted || 0
              }
              size={file.selectedFile.size}
              name={file.selectedFile.name}
              key={file.selectedFile.name}
            />
          ))
        : null}
    </div>
  );
}
