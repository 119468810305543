import React, { useEffect, useMemo, useState } from "react";
import { Button } from "../../../components/Buttons/Button";
import { createPortal } from "react-dom";
import SelectMealCustomizationModal from "../../../components/Modal/SelectMealCustomizationModal";
import noMealIcon from "../../../assets/images/no-food-image.png";
import { fetchMenuItemCustomizations } from "../../../httpQueries/http";
import Loader from "../../../components/Loader";

interface Props {
  name: string;
  description: string;
  price: string;
  image: string;
  count: number;
  categoryId: string;
  productId: string;
  setOrderedMeals: React.Dispatch<
    React.SetStateAction<
      {
        customizations: {
          customizationId: string;
          optionId: string[];
          markedPrice: number;
        }[];
        productId: string;
        markedPrice: number;
        notes: string;
        count: number;
      }[]
    >
  >;
  orderedMeals: {
    customizations: {
      customizationId: string;
      optionId: string[];
      markedPrice: number;
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
    count: number;
  }[];
  markedPrice: number;
  setOrders: React.Dispatch<
    React.SetStateAction<
      {
        agendaBlockId: number;
        customizations: {
          customizationId: string;
          optionId: string[];
          markedPrice: number;
        }[];
        productId: string;
        markedPrice: number;
        notes: string;
        count: number;
      }[]
    >
  >;
  orders: {
    agendaBlockId: number;
    customizations: {
      customizationId: string;
      optionId: string[];
      markedPrice: number;
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
    count: number;
  }[];
  agendaBlockId: number;
  showSelectCustomizationModal: string;
  setShowSelectCustomizationModal: React.Dispatch<React.SetStateAction<string>>;
  asyncOnAddMeal: () => Promise<void>;
}

export default function MenuItem({
  name,
  description,
  image,
  productId,
  markedPrice,
  setOrders,
  showSelectCustomizationModal,
  setShowSelectCustomizationModal,
  agendaBlockId,
  asyncOnAddMeal,
  orders,
  orderedMeals,
}: Props) {
  const token = localStorage.getItem("token");
  const currentProduct = useMemo(() => orderedMeals.find((item) => item.productId === productId), [productId, orderedMeals])
  const defaultOrder = currentProduct
    ? {
        agendaBlockId,
        ...currentProduct,
      }
    : undefined;
  const [notes, setNotes] = useState("");
  const [preOrder, setPreOrder] = useState<
    | {
        agendaBlockId: number;
        customizations: {
          customizationId: string;
          optionId: string[];
          markedPrice: number;
        }[];
        productId: string;
        markedPrice: number;
        notes: string;
        count: number;
      }
    | undefined
  >(defaultOrder);
  const [customizations, setCustomizations] = useState<
    | "loading"
    | {
        name: string;
        min_choice_options: number;
        max_choice_options: number;
        options: [
          {
            name: string;
            price: number;
            customizations: any[];
            min_qty: number;
            max_qty: number;
            conditional_price: {};
            formatted_price: string;
            default_qty: number;
            option_id: string;
          }
        ];
        customization_id: string;
      }[]
    | null
  >(null);

  useEffect(() => {
    if (!preOrder) {
      setPreOrder(defaultOrder);
    }
  }, [defaultOrder])

  console.log(orders, agendaBlockId, orderedMeals, orderedMeals.find((item) => item.productId === productId), productId, defaultOrder);

  return (
    <div className={`menuItem`}>
      {showSelectCustomizationModal === productId &&
      customizations !== "loading"
        ? createPortal(
            <SelectMealCustomizationModal
              name={name}
              description={description}
              agendaBlockId={agendaBlockId}
              customizations={customizations || []}
              setShow={() =>
                setShowSelectCustomizationModal((prevState) =>
                  prevState.length ? "" : prevState
                )
              }
              productId={productId}
              markedPrice={markedPrice}
              onDescrease={() => {
                setPreOrder((prevState) => {
                  let newOrders: {
                    agendaBlockId: number;
                    customizations: {
                      customizationId: string;
                      optionId: string[];
                      markedPrice: number;
                    }[];
                    productId: string;
                    markedPrice: number;
                    notes: string;
                    count: number;
                  };

                  if (!prevState) {
                    newOrders = {
                      agendaBlockId,
                      customizations: [],
                      productId: productId,
                      markedPrice: markedPrice,
                      notes: notes,
                      count: 1,
                    };
                  } else {
                    newOrders = {
                      ...prevState,
                      count: prevState?.count - 1,
                    };
                  }

                  return newOrders;
                });
              }}
              onIncrease={() => {
                setPreOrder((prevState) => {
                  let newOrders: {
                    agendaBlockId: number;
                    customizations: {
                      customizationId: string;
                      optionId: string[];
                      markedPrice: number;
                    }[];
                    productId: string;
                    markedPrice: number;
                    notes: string;
                    count: number;
                  };

                  if (!prevState) {
                    newOrders = {
                      agendaBlockId,
                      customizations: [],
                      productId: productId,
                      markedPrice: markedPrice,
                      notes: notes,
                      count: 1,
                    };
                  } else {
                    newOrders = {
                      ...prevState,
                      count: prevState?.count + 1,
                    };
                  }

                  return newOrders;
                });
              }}
              setTotalSum={() => {}}
              setOrders={setPreOrder}
              count={preOrder?.count || 0}
              onAddMeal={() => {
                if (preOrder) {
                  preOrder.notes = notes;
                  setOrders((prevState) => {
                    if (
                      !!prevState.find(
                        (order) => order.productId === preOrder?.productId
                      ) ||
                      !!prevState.find((order) => order.productId === productId)
                    ) {
                      return [
                        ...prevState.filter(
                          (order) => order.productId !== productId
                        ),
                        preOrder,
                      ];
                    } else {
                      return [...prevState, preOrder];
                    }
                  });
                  setShowSelectCustomizationModal("");
                }
              }}
              preOrder={preOrder}
              onInputChange={(count) => {
                const newCount = +count;
                setPreOrder((prevState) => {
                  let newOrders: {
                    agendaBlockId: number;
                    customizations: {
                      customizationId: string;
                      optionId: string[];
                      markedPrice: number;
                    }[];
                    productId: string;
                    markedPrice: number;
                    notes: string;
                    count: number;
                  };

                  if (!prevState) {
                    newOrders = {
                      agendaBlockId,
                      customizations: [],
                      productId: productId,
                      markedPrice: markedPrice,
                      notes: notes,
                      count: newCount,
                    };
                  } else {
                    newOrders = {
                      ...prevState,
                      count: newCount,
                    };
                  }

                  return newOrders;
                });
              }}
              onTextFieldChange={(e) => {
                setNotes(e.target.value);
                setPreOrder((prevState) => {
                  if (preOrder) {
                    preOrder.notes = notes;

                    return preOrder;
                  }

                  return prevState;
                });
              }}
              textFieldValue={notes}
            />,
            document.getElementById("modal") as HTMLElement
          )
        : null}
      <img src={image ? image : noMealIcon} alt="" className="menuItem-image" />
      <div className="menuItem-content">
        <div className="flex flex-column gap-8">
          <h3 className="menuItem-content-name">{name}</h3>
          <p className="menuItem-content-description">{description}</p>
        </div>
        <div className="flex fullWidth spaceBetween">
          {customizations === "loading" ? (
            <Loader size={"md"} scale="3.3" />
          ) : (
            <Button
              buttonType="regular"
              size="lg"
              hierarchy="linkColor"
              paddingNone
              onClick={async () => {
                setCustomizations("loading");
                const result = await fetchMenuItemCustomizations({
                  id: productId,
                  token,
                });
                setCustomizations(result || null);
                setShowSelectCustomizationModal(productId);
              }}
            >
              Add meal
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
