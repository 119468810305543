import React, { useEffect, useState } from "react";
import { Button } from "../Buttons/Button";
import { FormInput } from "../Inputs/FormInput";
import DurationsDropdown from "../Dropdown/DurationsDropdown";
import AttachDocumentsBlock from "./AttachDocumentsBlock";
import plusIcon from "../../assets/images/plus-primary.svg";
import { editSubBlockTime } from "../../utilities/editSubBlockTime";
import { getTimeInMinutes } from "../../utilities/getTimeInMinutes";
import { AgendaBlockType } from "../../types/agendaBlockType";
import { deleteSubBlocksDocuments } from "../../httpQueries/http";

interface Props {
  setSubBlocks: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        name: string;
        startTime: string;
        endTime: string;
        duration: {
          id: number;
          name: string;
          value?: number | undefined;
        } | null;
        index: number;
        docsId: number[];
        agendaItemId: string;
      }[]
    >
  >;
  startTime: string;
  disabled?: boolean;
  agendaItemId: string;
  editSubBlock: {
    id: string;
    name: string;
    startTime: string;
    endTime: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    } | null;
    index: number;
    docsId: number[];
    agendaItemId: string;
  } | null;
  setEditSubBlock: React.Dispatch<
    React.SetStateAction<{
      id: string;
      name: string;
      startTime: string;
      endTime: string;
      duration: {
        id: number;
        name: string;
        value?: number | undefined;
      } | null;
      index: number;
      docsId: number[];
      agendaItemId: string;
    } | null>
  >;
  subBlocks: {
    id: string;
    name: string;
    startTime: string;
    endTime: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    } | null;
    index: number;
    docsId: number[];
    agendaItemId: string;
  }[];
  blockStartTime: string,
  blockEndTime: string,
  agenda: {
    id: string;
    icon: string;
    name: string;
    description: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    };
    startTime: string;
    endTime: string;
    type: AgendaBlockType;
    index: number;
  }[],
  eventId: number,
}

export default function AgendaSubBlock({
  setSubBlocks,
  startTime,
  disabled,
  agendaItemId,
  editSubBlock,
  setEditSubBlock,
  subBlocks,
  blockStartTime,
  blockEndTime,
  agenda,
  eventId
}: Props) {
  const token = localStorage.getItem('token');
  const [name, setName] = useState<string | null>(null);
  const [selectedDuration, setSelectedDuration] = useState<{
    id: number;
    name: string;
    value?: number;
  } | null>(null);
  const [docsId, setDocsId] = useState<number[]>([]);
  const [nameError, setNameError] = useState<null | string>(null);
  const [durationError, setDurationError] = useState<boolean>(false);
  const [clearFiles, setClearFiles] = useState(false);

  const createNewItem = () => {
    if (!name || !name?.trim().length) {
      setNameError("Please, enter the name.");
    }

    if (!selectedDuration) {
      setDurationError(true);
    }

    if (!name || !name?.trim().length || !selectedDuration) {
      return;
    }

    let endHour;

    let endMinutes = 0;

    if (selectedDuration) {
      if (selectedDuration.value! >= 60) {
        endHour =
          +startTime.split(":")[0] + Math.floor(selectedDuration.value! / 60);

        endMinutes =
          selectedDuration.value! +
          +startTime.split(":")[1].slice(0, 2) -
          (endHour - +startTime.split(":")[0]) * 60;

        if (endMinutes >= 60) {
          endHour = endHour + Math.floor(endMinutes / 60);
          endMinutes = endMinutes - Math.floor(endMinutes / 60) * 60;
        }
      } else {
        endHour = +startTime.split(":")[0];
        endMinutes =
          selectedDuration.value! + +startTime.split(":")[1].slice(0, 2);

        if (endMinutes >= 60) {
          endHour = endHour + Math.floor(endMinutes / 60);
          endMinutes = endMinutes - Math.floor(endMinutes / 60) * 60;
        }
      }
    }

    const endTime = `${endHour}:${
      endMinutes! > 0 ? (endMinutes < 10 ? `0${endMinutes}` : endMinutes) : "00"
    } AM`;

    return {
      name: name || "",
      startTime: startTime,
      endTime: endTime,
      duration: selectedDuration,
      docsId: docsId,
      agendaItemId: agendaItemId,
    };
  };

  const onAdd = () => {
    const newItem = createNewItem();

    if (newItem) {
      setSubBlocks((prevState) => [
        ...prevState,
        {
          id: `${prevState.length}`,
          index: prevState.length,
          ...newItem,
        },
      ]);
      setName(null);
      setSelectedDuration(null);
    }

    setDocsId([]);
    setClearFiles(true);
  };

  const onSave = () => {
    const newItem = createNewItem();

    if (newItem && editSubBlock) {
      setSubBlocks((prevState) =>
        [
          ...prevState.filter((block) => block.id !== editSubBlock.id),
          {
            id: editSubBlock.id,
            index: editSubBlock.index,
            ...newItem,
          },
        ].sort((block1, block2) => block1.index - block2.index)
      );
      setName(null);
      setSelectedDuration(null);
      setEditSubBlock(null);
      editSubBlockTime(
        [
          ...subBlocks.filter((block) => block.id !== editSubBlock.id),
          {
            id: editSubBlock.id,
            index: editSubBlock.index,
            ...newItem,
          },
        ].sort((block1, block2) => block1.index - block2.index),
        blockStartTime,
        agenda,
        setSubBlocks
      );
    }

    setDocsId([]);
    setClearFiles(true);
  };

  const getTimeToBlockEnd = () => {
    if (blockEndTime?.length) {
      if (subBlocks.length && !editSubBlock) {
        return getTimeInMinutes(blockEndTime) - getTimeInMinutes(subBlocks[subBlocks.length - 1].endTime);
      }
  
      if (editSubBlock && editSubBlock.duration?.value) {
        return (getTimeInMinutes(blockEndTime) + editSubBlock.duration?.value) - getTimeInMinutes(subBlocks[subBlocks.length - 1].endTime)
      }

      if (!subBlocks.length && !editSubBlock) {
        return getTimeInMinutes(blockEndTime) - getTimeInMinutes(blockStartTime);
      }
    }

    return undefined
  }
 
  useEffect(() => {
    if (editSubBlock) {
      setName(editSubBlock.name);
      setSelectedDuration(editSubBlock.duration);
    }
  }, [editSubBlock]);

  return (
    <div className="flex flex-column gap-24 border-radius-12 border-gray px-12 py-16">
      <div className="flex items-center spaceBetween">
        <h4 className="text-lg font-semibold color-gray-950">Item</h4>
        <Button
          size={"md"}
          hierarchy={"errorTertiaryGray"}
          buttonType={"regular"}
          paddingNone
          disabled={disabled && !editSubBlock}
          hoverNone
          asyncOnClick={async () => {
            setName(null);
            setSelectedDuration(null);
            await deleteSubBlocksDocuments({ token, body: { unusedDocsIds: docsId } });

            if (editSubBlock) {
              setSubBlocks((prevState) =>
                prevState.filter((block) => block.id !== editSubBlock.id)
              );
              setEditSubBlock(null);
            }

            setDocsId([]);
            setClearFiles(true);
          }}
        >
          Remove
        </Button>
      </div>
      <FormInput
        label="Description"
        type="Text"
        value={name || ""}
        onChange={(
          event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
          setName(event.target.value);
          if (!!event.target.value.trim().length) {
            setNameError(null);
          }
        }}
        isError={!!nameError}
        errorMessage={nameError || ""}
        design="new"
        disabled={disabled && !editSubBlock}
      />
      <DurationsDropdown
        isError={durationError}
        selectedDuration={selectedDuration}
        setSelectedDuration={setSelectedDuration}
        isSmallTimeLaps
        disabled={disabled && !editSubBlock}
        onSelect={() => {
          setDurationError(false);
        }}
        maxAvailableTimeLap={getTimeToBlockEnd()}
      />
      <AttachDocumentsBlock 
        disabled={disabled && !editSubBlock} 
        eventId={eventId} 
        addDocumentToSession={(documentId) => setDocsId(prevState => [...prevState, documentId])} 
        removeDocumentFromSession={(documentId) => setDocsId(prevState => prevState.filter(doc => doc !== documentId))}
        clearFiles={clearFiles}
      />
      <Button
        size={"md"}
        hierarchy={"linkColor"}
        buttonType={"regular"}
        disabled={disabled && !editSubBlock}
      >
        <div className="flex items-center gap-8">
          <img src={plusIcon} alt="plus" />
          <p>Add link</p>
        </div>
      </Button>
      <Button
        size={"md"}
        hierarchy={"newDesign-primary"}
        buttonType={"regular"}
        newDesignPaddingNone
        className="ml-auto"
        disabled={disabled && !editSubBlock}
        onClick={editSubBlock ? onSave : onAdd}
      >
        {editSubBlock ? "Save" : "Add"}
      </Button>
    </div>
  );
}
