import { DropResult } from "react-beautiful-dnd";

export const onSubBlocksDragEnd = (
  result: DropResult,
  setIsDrag: React.Dispatch<React.SetStateAction<boolean>>,
  subBlocks: {
    id: string;
    name: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    } | null;
    startTime: string;
    endTime: string;
    index: number;
    docsId: number[];
    agendaItemId: string
  }[],
  setSubBlocks: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        name: string;
        duration: {
          id: number;
          name: string;
          value?: number | undefined;
        } | null;
        startTime: string;
        endTime: string;
        index: number;
        docsId: number[];
        agendaItemId: string
      }[]
    >
  >
) => {
  const { destination, source } = result;

  if (!destination || !source) {
    return;
  }

  const agendaWithoutDraggableItem = [
    ...subBlocks.slice(0, source.index),
    ...subBlocks.slice(source.index + 1),
  ];

  let updatedAgendaArray: {
    id: string;
    name: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    } | null;
    startTime: string;
    endTime: string;
    index: number;
    docsId: number[];
    agendaItemId: string
  }[];

  updatedAgendaArray = [
    ...agendaWithoutDraggableItem.slice(0, destination?.index),
    subBlocks[source.index],
    ...agendaWithoutDraggableItem.slice(destination?.index),
  ].map((item, index) => ({ ...item, index }));

  setSubBlocks(updatedAgendaArray);

  setIsDrag(true);
};
