import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "./slice.init";
import { TCurrentRoutePayload } from "./slice.types";

export const slice = createSlice({
  name: "refetchEventsFunction",
  initialState,
  reducers: {
    setRefetchEventsFunction: (state, action: PayloadAction<TCurrentRoutePayload>) => {
      state.refetch = action.payload.refetch;
    }
  },
});
